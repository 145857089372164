<template>
	<ValidationProvider
		v-slot="{ classes, errors }"
		ref="amount"
		:name="`order_amount-${id}`"
		rules="requiredExport|isNumber"
	>
		<div :class="['col-12', classes]">
			<input
				v-model.trim="amount"
				type="text"
				class="form-control amount__input"
				:disabled="disabled"
				:maxlength="ORDER_RULE.AMOUNT"
				@paste="handleOnPaste($event)"
				@input="handleSelectAmount"
				@keydown="checkValidPhone($event)"
			/>
			<div v-if="errors.length" class="error-mess">
				{{ errors[0] }}
			</div>
		</div>
	</ValidationProvider>
</template>

<script>
import { createNamespacedHelpers } from "vuex"
import { SET_AMOUNTS } from "../store/mutation-types"
import { ORDER_RULE, REGEX } from "../../../shared/plugins/constants"
const { mapMutations, mapState } = createNamespacedHelpers("orders")
export default {
	name: "AmountItem",
	props: {
		id: {
			type: String,
			default: null,
		},
		value: {
			type: [Number, String],
			default: null,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		newUpdateHasStatusRevision: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			amount: null,
			ORDER_RULE,
		}
	},
	computed: {
		...mapState(["amounts"]),
	},
	watch: {
		value(val) {
			if (val) this.amount = this.value
		},
		newUpdateHasStatusRevision(val) {
			if (val) return (this.amount = 0)
			return (this.amount = null)
		},
	},
	created() {
		if (this.amounts?.length) {
			this.amounts.forEach(el => {
				if (el.id === this.id) {
					this.amount = el.amount
				}
			})
		}
		if (this.value) return (this.amount = this.value)
		if (this.newUpdateHasStatusRevision && this.value === 0) return (this.amount = this.value)
		if (this.newUpdateHasStatusRevision) return (this.amount = 0)
	},
	methods: {
		...mapMutations({ SET_AMOUNTS }),
		handleSelectAmount() {
			const valid = REGEX.IS_NUMBER.test(this.amount)

			if (!valid) return

			const payload = {
				id: this.id,
				amount: this.amount ? parseInt(this.amount) : 0,
			}
			const newAmounts = this.amounts
			const index = newAmounts.findIndex(el => el.id === payload.id)
			if (index < 0) {
				newAmounts.push(payload)
			} else {
				newAmounts[index] = payload
			}
			this.SET_AMOUNTS(newAmounts)
			this.$emit("amount-change")
		},
	},
}
</script>
<style lang="scss" scoped>
.amount__input {
	max-width: 120px;
	margin: auto;
	text-align: center;
	min-width: 70px;
}
</style>
